import { Logo } from 'components/logo';
import { alpha, Box, Container, Divider, Stack, Typography } from '@mui/material';
import { PAYMENT_LIST } from 'config';

// license
import LicenseIcon from 'assets/img/payments/generate.png';

const Footer = () => {
    return (
        <Box
            component="div"
            sx={{
                bgcolor: (theme) => alpha(theme.palette.background.default, 0.8)
            }}
        >
            <Container>
                <Stack sx={{ py: 4 }}>
                    <Divider />
                    <Stack
                        sx={{ py: 2 }}
                        direction="row"
                        justifyContent="space-evenly"
                        alignItems="center"
                        flexWrap="wrap"
                    >
                        {PAYMENT_LIST.map((img, i) => (
                            <Box
                                key={i}
                                component="img"
                                src={img}
                                sx={{ height: { md: 40, xs: 34 }, px: { md: 2, xs: 1 }, py: 1 }}
                            />
                        ))}
                    </Stack>
                    <Divider />
                    <Stack sx={{ py: 1 }} direction="row" alignItems="center" justifyContent="space-between">
                        <Logo />
                        <Stack spacing={2} alignItems={'flex-start'}>
                            <a
                                href="https://licensing.newwavelicensing.ph/validator/oj36t/2b204b50-bcb5-4329-83e4-d44e25120435"
                                rel="noreferrer"
                                target="_blank"
                            >
                                <Box component="img" src={LicenseIcon} sx={{ width: 50 }} />
                            </a>
                        </Stack>
                    </Stack>
                    <Divider />
                    <Typography color="text.secondary" variant={'subtitle2'} sx={{ textAlign: 'center', pt: 3 }}>
                        TM + © 2023 Royalcasino.vip All rights reserved
                    </Typography>
                </Stack>
            </Container>
        </Box>
    );
};

export default Footer;
