import { useCallback } from 'react';
import { Icon } from '@iconify/react';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
// form
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import { LoadingButton } from '@mui/lab';
// hook
import useSettings from 'hooks/use-setting-context';
import useAuthContext from 'hooks/use-auth-context';
// components
import { Logo } from 'components/logo';
import { changePassword } from 'utils/api';
import { useLocales } from 'locales';

type FormValuesProps = {
    OldPassword: string;
    NewPassword: string;
    ConfirmPassword: string;
    afterSubmit?: string;
};

const ChangePasswordModal = () => {
    const { isLogined } = useAuthContext();
    const { settings, updateSettings } = useSettings();
    const { t } = useLocales();

    const ChangePasswordSchema = Yup.object().shape({
        OldPassword: Yup.string().required('Old password is required'),
        NewPassword: Yup.string().required('Password is required'),
        ConfirmPassword: Yup.string().required('Confirm is required')
    });

    const defaultValues = {
        OldPassword: '',
        ConfirmPassword: '',
        NewPassword: ''
    };

    const methods = useForm<FormValuesProps>({
        resolver: yupResolver(ChangePasswordSchema),
        defaultValues
    });

    const {
        control,
        setError,
        handleSubmit,
        formState: { errors, isSubmitting }
    } = methods;

    const onSubmit = async (data: any) => {
        try {
            const res = await changePassword(data);
            if (res.success) {
                toast.success(res.message);
                updateSettings({ changePasswordModal: false });
            } else {
                toast.error(res.message);
            }
        } catch (error: any) {
            setError('afterSubmit', {
                ...error,
                message: error.title || error.Errors[0]
            });
        }
    };

    const closeModal = useCallback(() => {
        if (isSubmitting) return;
        updateSettings({ changePasswordModal: false });
    }, [isSubmitting, updateSettings]);

    return (
        <Dialog
            open={settings.changePasswordModal && isLogined}
            onClose={closeModal}
            sx={{
                '& .MuiDialog-paper': {
                    boxShadow: '0 0 20px 0px #d4a017',
                    bgcolor: 'background.paper'
                }
            }}
        >
            <DialogContent sx={{ pb: 3 }}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ py: 2 }}>
                    <Typography sx={{ fontWeight: 600, color: 'text.secondary' }}>{t('change_password')}</Typography>
                    <IconButton onClick={closeModal}>
                        <Icon icon="ic:baseline-close" color="#637381" width="18" height="18" />
                    </IconButton>
                </Stack>
                <Divider />
                <Box sx={{ py: 6, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Logo width={200} height={70} />
                </Box>
                <Stack spacing={2} component="form" onSubmit={handleSubmit(onSubmit)}>
                    <Controller
                        name="OldPassword"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <TextField
                                {...field}
                                fullWidth
                                error={!!error}
                                helperText={error ? error?.message : ''}
                                variant="filled"
                                label={t('current_password')}
                                type="password"
                                sx={{ minWidth: { sm: 400, xs: '100%' } }}
                                inputProps={{
                                    autoComplete: 'off'
                                }}
                            />
                        )}
                    />
                    <Controller
                        name="NewPassword"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <TextField
                                {...field}
                                fullWidth
                                error={!!error}
                                helperText={error ? error?.message : ''}
                                variant="filled"
                                label={t('Password')}
                                type="password"
                                sx={{ minWidth: { sm: 400, xs: '100%' } }}
                                inputProps={{
                                    autoComplete: 'off'
                                }}
                            />
                        )}
                    />
                    <Controller
                        name="ConfirmPassword"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <TextField
                                {...field}
                                fullWidth
                                error={!!error}
                                helperText={error ? error?.message : ''}
                                variant="filled"
                                label={t('confirm_password')}
                                type="password"
                                sx={{ minWidth: { sm: 400, xs: '100%' } }}
                                inputProps={{
                                    autoComplete: 'off'
                                }}
                            />
                        )}
                    />
                    {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}
                    <Stack width="100%" mt={2}>
                        <LoadingButton
                            loading={isSubmitting}
                            size="large"
                            color="secondary"
                            variant="contained"
                            type="submit"
                            fullWidth
                        >
                            {t('Change')}
                        </LoadingButton>
                    </Stack>
                </Stack>
            </DialogContent>
        </Dialog>
    );
};
export default ChangePasswordModal;
