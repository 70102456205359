import { useCallback, useEffect, useState } from 'react';
import { Icon } from '@iconify/react';
import * as Yup from 'yup';
// form
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import Skeleton from '@mui/material/Skeleton';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { LoadingButton } from '@mui/lab';
// hook
import useSettings from 'hooks/use-setting-context';
import { useBoolean } from 'hooks/use-boolean';
// components
import { Logo } from 'components/logo';
import { useLocales } from 'locales';
import { forgotPassword, getCaptcha } from 'utils/api';

type FormValuesProps = {
    Email: string;
    Captcha: string;
    afterSubmit?: string;
};

const ForgotPasswordModal = () => {
    const { settings, updateSettings } = useSettings();
    const { t } = useLocales();

    const showPassword = useBoolean();
    const [captcha, setCaptcha] = useState('');

    const LoginSchema = Yup.object().shape({
        Email: Yup.string().email().required('Email is required'),
        Captcha: Yup.string().required('Security Code is required')
    });

    const defaultValues = {
        Email: '',
        Captcha: ''
    };

    const methods = useForm<FormValuesProps>({
        resolver: yupResolver(LoginSchema),
        defaultValues
    });

    const {
        control,
        setError,
        handleSubmit,
        formState: { errors, isSubmitting }
    } = methods;

    const onSubmit = async (data: any) => {
        try {
            await forgotPassword({ Email: data.Email, Captcha: data.Captcha });
        } catch (error: any) {
            console.log(error);
            setError('afterSubmit', {
                ...error,
                message: typeof error === 'string' ? error : error.Errors[0]
            });
        }
    };

    const closeModal = useCallback(() => {
        if (isSubmitting) return;
        updateSettings({ forgotPasswordModal: false });
    }, [isSubmitting, updateSettings]);

    const fetchCaptcha = async () => {
        try {
            const img = await getCaptcha();
            setCaptcha(img);
        } catch (error) {
            console.error('Error fetching captcha', error);
        }
    };

    useEffect(() => {
        fetchCaptcha();
        // eslint-disable-next-line
    }, [settings.forgotPasswordModal]);

    return (
        <Dialog
            open={settings.forgotPasswordModal}
            onClose={closeModal}
            sx={{
                '& .MuiDialog-paper': {
                    boxShadow: '0 0 20px 0px #d4a017',
                    bgcolor: 'background.paper'
                }
            }}
        >
            <DialogContent sx={{ pb: 3 }}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ py: 2 }}>
                    <Typography sx={{ fontWeight: 600, color: 'text.secondary' }}>
                        {t('forgot_security_code')}
                    </Typography>
                    <IconButton onClick={closeModal}>
                        <Icon icon="ic:baseline-close" color="#637381" width="18" height="18" />
                    </IconButton>
                </Stack>
                <Divider />
                <Box sx={{ py: 6, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Logo width={200} height={70} />
                </Box>
                <Stack spacing={2} component="form" onSubmit={handleSubmit(onSubmit)}>
                    <Controller
                        name="Email"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <TextField
                                {...field}
                                fullWidth
                                error={!!error}
                                helperText={error ? error?.message : ''}
                                variant="filled"
                                label={t('Email')}
                                sx={{ minWidth: { sm: 400, xs: '100%' } }}
                                inputProps={{
                                    autoComplete: 'off'
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Icon
                                                icon="ic:baseline-alternate-email"
                                                color="#637381"
                                                width="18"
                                                height="18"
                                            />
                                        </InputAdornment>
                                    )
                                }}
                            />
                        )}
                    />
                    <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
                        <Controller
                            name="Captcha"
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                                <TextField
                                    {...field}
                                    fullWidth
                                    error={!!error}
                                    helperText={error ? error?.message : ''}
                                    variant="filled"
                                    label={t('security_code')}
                                    type={showPassword.value ? 'text' : 'code'}
                                    sx={{ width: '50%' }}
                                    inputProps={{
                                        autoComplete: 'off'
                                    }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Icon
                                                    icon="ic:baseline-security"
                                                    color="#637381"
                                                    width="18"
                                                    height="18"
                                                />
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            )}
                        />
                        {captcha ? (
                            <Box
                                component="img"
                                sx={{ width: '50%', height: 56, borderRadius: 1 }}
                                src={`data:image/png;base64,${captcha}`}
                            />
                        ) : (
                            <Skeleton sx={{ width: '50%', height: 56, borderRadius: 1 }} />
                        )}
                    </Stack>
                    {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}
                    <Stack width="100%" mt={2}>
                        <LoadingButton
                            loading={isSubmitting}
                            size="large"
                            color="secondary"
                            variant="contained"
                            type="submit"
                            fullWidth
                        >
                            {t('forgot_password')}
                        </LoadingButton>
                    </Stack>
                </Stack>
            </DialogContent>
        </Dialog>
    );
};
export default ForgotPasswordModal;
