import { Link as ReactLink, useLocation } from 'react-router-dom';
// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
// swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
// config
import { MENU_LIST } from 'config';
import { useRouter } from 'hooks/use-router';
import { useEffect, useState } from 'react';
import { useLocales } from 'locales';

const MenuList = () => {
    const router = useRouter();
    const location = useLocation();
    const { t } = useLocales();
    const [active, setActive] = useState(-1);

    const openSportLink = (link: string, order: number) => {
        setActive(order);
        try {
            if (location.pathname === '/') {
                if (window.bt) {
                    window.bt.updateOptions({ url: link });
                }
            } else {
                router.replace(`/?bt-path=%2F${link}`);
            }
        } catch {
            router.replace(`/?bt-path=%2F${link}`);
        }
    };

    useEffect(() => {
        if (location.pathname === '/live-casino') {
            setActive(0);
        } else if (location.pathname === '/slots') {
            setActive(1);
        } else if (location.pathname === '/' && location.search) {
            const param = location.search.split('bt-path=%2F')[1];
            const activeItem = MENU_LIST.find((item) => item.param && item.param === param);
            if (activeItem) {
                setActive(activeItem.order);
            }
        }
    }, [location]);

    return (
        <Box
            sx={{
                '& .menu-swiper .swiper-wrapper': {
                    alignItems: 'center',
                    height: 75
                }
            }}
        >
            <Swiper
                modules={[Navigation, Pagination, Scrollbar, A11y]}
                spaceBetween={0}
                slidesPerView={20}
                breakpoints={{
                    200: {
                        slidesPerView: 4
                    },
                    400: {
                        slidesPerView: 5
                    },
                    425: {
                        slidesPerView: 6
                    },
                    // when window width is >= 640px
                    640: {
                        slidesPerView: 7
                    },
                    // when window width is >= 768px
                    768: {
                        slidesPerView: 8
                    },
                    // when window width is >= 1024px
                    1024: {
                        slidesPerView: 12
                    },
                    // when window width is >= 1280px
                    1280: {
                        slidesPerView: 20
                    }
                }}
                navigation={true}
                className="menu-swiper"
            >
                {MENU_LIST.map(({ icon, name, link, param, divide, order }, i) => (
                    <SwiperSlide key={i} className={order === active ? 'active' : ''}>
                        <Stack
                            justifyContent="center"
                            alignItems="center"
                            sx={{
                                height: '100%',
                                cursor: 'pointer',
                                position: 'relative',
                                '&:hover': {
                                    '& p': {
                                        color: 'text.primary'
                                    }
                                }
                            }}
                            className={divide ? 'divide' : ''}
                            {...(link && {
                                component: ReactLink,
                                to: link
                            })}
                            {...(param && {
                                onClick: () => openSportLink(param, order)
                            })}
                        >
                            <Box sx={{ width: 39, height: 37, p: '6px' }} component="img" src={icon} />
                            <Typography sx={{ fontSize: 12, color: 'text.secondary', fontWeight: 500 }}>{t(name)}</Typography>
                        </Stack>
                    </SwiperSlide>
                ))}
            </Swiper>
        </Box>
    );
};

export default MenuList;
