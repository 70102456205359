import React, { useCallback, useState } from 'react';
import { Icon } from '@iconify/react';
// @mui
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Dialog from '@mui/material/Dialog';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
// hook
import useSettings from 'hooks/use-setting-context';
import useAuthContext from 'hooks/use-auth-context';
// components
import Payments from './Payments';
import Games from './Games';
import Transactions from './Transactions';
import { useLocales } from 'locales';

const HistoryModal = () => {
    const { isLogined } = useAuthContext();
    const { settings, updateSettings } = useSettings();
    const [mainTab, setMainTab] = useState(0);
    const [historyTab, setHistoryTab] = useState(0);
    const { t } = useLocales();

    const handleMainTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setMainTab(newValue);
    };

    const handleHistoryTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setHistoryTab(newValue);
    };

    const closeModal = useCallback(() => {
        updateSettings({ historyModal: false });
    }, [updateSettings]);

    return (
        <Dialog
            fullWidth
            maxWidth="xl"
            open={settings.historyModal && isLogined}
            onClose={closeModal}
            sx={{
                '& .MuiDialog-paper': {
                    boxShadow: '0 0 20px 0px #d4a017',
                    bgcolor: 'background.paper'
                }
            }}
        >
            <DialogContent sx={{ pb: 3 }}>
                <Stack sx={{ py: 2 }}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                        <Box>
                            <Tabs
                                value={mainTab}
                                onChange={handleMainTabChange}
                                textColor="secondary"
                                TabIndicatorProps={{
                                    sx: {
                                        bgcolor: 'secondary.main'
                                    }
                                }}
                            >
                                <Tab
                                    icon={<Icon icon="solar:wallet-2-bold-duotone" width="24" height="24" />}
                                    iconPosition="start"
                                    label={t('Purse')}
                                />
                                <Tab
                                    icon={<Icon icon="solar:bill-list-bold-duotone" width="24" height="24" />}
                                    iconPosition="start"
                                    label={t('History')}
                                />
                            </Tabs>
                        </Box>
                        <IconButton onClick={closeModal}>
                            <Icon icon="ic:baseline-close" color="#637381" width="18" height="18" />
                        </IconButton>
                    </Stack>
                    <Divider />
                </Stack>
                <Stack>
                    <Box sx={{ bgcolor: 'background.default' }}>
                        <Tabs
                            value={historyTab}
                            onChange={handleHistoryTabChange}
                            sx={{
                                width: '100%',
                                '& .MuiTabs-flexContainer': {
                                    justifyContent: 'center'
                                },
                                '& .MuiTab-root': {
                                    flex: 1,
                                    mr: 0
                                }
                            }}
                            TabIndicatorProps={{
                                sx: {
                                    bgcolor: 'secondary.main'
                                }
                            }}
                        >
                            <Tab label={t('Payments')} />
                            <Tab label={t('Games')} />
                            <Tab label={t('Transactions')} />
                        </Tabs>
                    </Box>
                    {(() => {
                        switch (historyTab) {
                            case 0:
                                return <Payments />;
                            case 1:
                                return <Games />;
                            case 2:
                                return <Transactions />;
                            default:
                                return <Payments />;
                        }
                    })()}
                </Stack>
            </DialogContent>
        </Dialog>
    );
};
export default HistoryModal;
