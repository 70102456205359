import { useCallback } from 'react';
import { Icon } from '@iconify/react';
import * as Yup from 'yup';
// form
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { LoadingButton } from '@mui/lab';
// hook
import useAuthContext from 'hooks/use-auth-context';
import useSettings from 'hooks/use-setting-context';
import { useBoolean } from 'hooks/use-boolean';
// components
import { Logo } from 'components/logo';
import { useLocales } from 'locales';

type FormValuesProps = {
    username: string;
    password: string;
    afterSubmit?: string;
};

const LoginModal = () => {
    const { settings, updateSettings } = useSettings();
    const { login } = useAuthContext();
    const { t } = useLocales();

    const showPassword = useBoolean();

    const LoginSchema = Yup.object().shape({
        username: Yup.string().required('Username is required'),
        password: Yup.string().required('Password is required')
    });

    const defaultValues = {
        username: '',
        password: ''
    };

    const methods = useForm<FormValuesProps>({
        resolver: yupResolver(LoginSchema),
        defaultValues
    });

    const {
        control,
        setError,
        handleSubmit,
        formState: { errors, isSubmitting }
    } = methods;

    const onSubmit = async (data: any) => {
        try {
            await login(data.username, data.password);
        } catch (error: any) {
            setError('afterSubmit', {
                ...error,
                message: error.Errors[0]
            });
        }
    };

    const closeModal = useCallback(() => {
        if (isSubmitting) return;
        updateSettings({ loginModal: false });
    }, [isSubmitting, updateSettings]);

    return (
        <Dialog
            open={settings.loginModal}
            onClose={closeModal}
            sx={{
                '& .MuiDialog-paper': {
                    boxShadow: '0 0 20px 0px #d4a017',
                    bgcolor: 'background.paper'
                }
            }}
        >
            <DialogContent sx={{ pb: 3 }}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ py: 2 }}>
                    <Typography sx={{ fontWeight: 600, color: 'text.secondary' }}>{t('Login')}</Typography>
                    <IconButton onClick={closeModal}>
                        <Icon icon="ic:baseline-close" color="#637381" width="18" height="18" />
                    </IconButton>
                </Stack>
                <Divider />
                <Box sx={{ py: 6, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Logo width={200} height={70} />
                </Box>
                <Stack spacing={2} component="form" onSubmit={handleSubmit(onSubmit)}>
                    <Controller
                        name="username"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <TextField
                                {...field}
                                fullWidth
                                error={!!error}
                                helperText={error ? error?.message : ''}
                                variant="filled"
                                label={t('Username')}
                                sx={{ minWidth: { sm: 400, xs: '100%' } }}
                                inputProps={{
                                    autoComplete: 'off'
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Icon icon="fa6-solid:user" color="#637381" width="18" height="18" />
                                        </InputAdornment>
                                    )
                                }}
                            />
                        )}
                    />
                    <Controller
                        name="password"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <TextField
                                {...field}
                                fullWidth
                                error={!!error}
                                helperText={error ? error?.message : ''}
                                variant="filled"
                                label={t('Password')}
                                type={showPassword.value ? 'text' : 'password'}
                                sx={{ minWidth: { sm: 400, xs: '100%' } }}
                                inputProps={{
                                    autoComplete: 'off'
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Icon icon="fa6-solid:key" color="#637381" width="18" height="18" />
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={showPassword.onToggle}>
                                                <Icon
                                                    icon={showPassword.value ? 'mdi:eye-off' : 'mdi:eye'}
                                                    color="#637381"
                                                    width="25"
                                                    height="25"
                                                />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        )}
                    />
                    <Typography
                        onClick={() => updateSettings({ forgotPasswordModal: true, loginModal: false })}
                        sx={{
                            color: 'text.secondary',
                            cursor: 'pointer',
                            '&:hover': { color: 'text.primary' }
                        }}
                    >
                        {t('forgot_password')}
                    </Typography>
                    {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}
                    <Stack width="100%" mt={2}>
                        <LoadingButton
                            loading={isSubmitting}
                            size="large"
                            color="secondary"
                            variant="contained"
                            type="submit"
                            fullWidth
                        >
                            {t('Login')}
                        </LoadingButton>
                    </Stack>
                </Stack>
            </DialogContent>
        </Dialog>
    );
};
export default LoginModal;
