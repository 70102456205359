import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import { Provider as ReduxProvider } from 'react-redux';
// redux
import { store } from './store/store';

import { AuthProvider } from 'context/auth/auth-context';
import { AuthConsumer } from 'context/auth/auth-consumer';
import { SettingsProvider } from 'context/setting/setting-context';
import ThemeProvider from 'theme';

import ScrollToTop from 'components/ScrollToTop';
import { LocalizationProvider } from 'locales';
import Router from 'routes/sections';
import LoginModal from 'components/Modals/Login';
import RegisterModal from './components/Modals/Register';
import ForgotPasswordModal from 'components/Modals/ForgotPassword';
import ChangePasswordModal from 'components/Modals/ChangePassword';
import HistoryModal from 'components/Modals/History';

const App = () => {
    return (
        <ReduxProvider store={store}>
            <SettingsProvider>
                <LocalizationProvider>
                    <AuthProvider>
                        <HelmetProvider>
                            <ThemeProvider>
                                <AuthConsumer>
                                    <BrowserRouter>
                                        <ScrollToTop />
                                        <Router />
                                        <LoginModal />
                                        <RegisterModal />
                                        <ForgotPasswordModal />
                                        <ChangePasswordModal />
                                        <HistoryModal />
                                    </BrowserRouter>
                                </AuthConsumer>
                            </ThemeProvider>
                        </HelmetProvider>
                    </AuthProvider>
                </LocalizationProvider>
            </SettingsProvider>
        </ReduxProvider>
    );
};

export default App;
