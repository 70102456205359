import React from 'react';
import Fab from '@mui/material/Fab';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TelegramIcon from '@mui/icons-material/Telegram';

interface FloatingButtonsProps {
    whatsappLink: string;
    telegramLink: string;
}

export const FloatingButtons: React.FC<FloatingButtonsProps> = ({ whatsappLink, telegramLink }) => {
    return (
        <div className="floating-buttons">
            {/* WhatsApp Button */}
            <Fab
                aria-label="whatsapp"
                href={whatsappLink}
                target="_blank"
                style={{
                    position: 'fixed',
                    bottom: '75px',
                    left: '10px',
                    backgroundColor: '#25D366', // WhatsApp green color
                    color: 'white'
                }}
            >
                <WhatsAppIcon style={{ fontSize: '35px' }} />
            </Fab>

            {/* Telegram Button */}
            <Fab
                aria-label="telegram"
                href={telegramLink}
                target="_blank"
                style={{
                    position: 'fixed',
                    bottom: '10px',
                    left: '10px',
                    backgroundColor: '#0088cc', // Telegram blue color
                    color: 'white'
                }}
            >
                <TelegramIcon style={{ fontSize: '35px' }} />
            </Fab>
        </div>
    );
};